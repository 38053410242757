.Testi-main {
  width: 80%;
  margin: 20px auto;
  display: flex;
  max-width: 1200px;
}

.Testi-img {
  width: 50%;
}
.Freams {
  height: 470px;
  width: 90%;
  margin: 0 auto;
  display: flex;
}
.Clint-testi {
  width: 50%;
}
.Testi-text {
  font-family: var(--Font-Family);
  font-size: 20px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0;
  text-align: left;
  margin: 0;
  color: #000;
}
.Main-texthead {
  font-family: var(--Font-Family);
  font-size: 50px;
  font-weight: 600;
  line-height: 55px;
  color: var(--Blue);
  margin-top: 10px;
}
.Client-slider {
  width: 100%;
  /* border: 1px solid red; */
  padding: 10px 0px;
}
.Client-Main-Compo {
  width: 100%;
  /* margin: 0 auto; */
}
.Clinet-content {
  width: 80%;
  margin: 10px auto;
}
.Avtart {
  height: 65px !important;
  width: 65px !important;
  border-radius: 100%;
  margin: 0 auto;
}
.Articals {
  height: 35px;
  width: 35px;
}
.Articals2 {
  height: 35px;
  width: 35px;
  display: flex !important;
  margin-left: auto;
}
.Main-Content {
  font-family: var(--Font-Family);
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: center;
  color: #000;
  margin: 0;
}
.Clinet-name {
  font-size: 20px;
  font-weight: 600;
  font-family: var(--Font-Family);
  color: var(--Yellow);
  text-align: center;
  margin: 0;
  line-height: 14px;
}
.Designation {
  font-size: 16px;
  font-family: var(--Font-Family);
  color: var(--Blue);
  text-align: center;
  margin: 0;
  line-height: 32px;
}
.slider2 .slick-dots li button {
  position: absolute !important;
  height: 20px !important;
  width: 20px !important;
}
.slider2 .slick-dots li {
  width: 10px !important;
}
.slider2 .slick-dots li.slick-active button:before {
  color: #055393 !important;
  font-size: 315px !important;
  opacity: 10 !important;
  font-weight: 100;
  top: -112px;
  transition: 1s ease-in-out;
}
.slider2 .slick-dots li.slick-active button:before {
  color: var(--Blue) !important;
  font-size: 100px !important;
  opacity: 10 !important;
  font-weight: 100;
  transition: 1s ease-in-out;
}
/* .slider2 .slick-dots li button {
  background: red !important;
} */
.slider2 .slick-dots li button::before {
  color: #82a9c9 !important;
  font-size: 100px !important;
  opacity: 10 !important;
  /* margin-top: 30px; */
  content: "." !important;
  font-weight: 100;
  top: 0 !important;
}
.slider2 .slick-dots {
  height: auto !important;
  overflow: visible;
}
.slider2 .slick-dots {
  bottom: 14px !important;
}
@media all and (max-width: 1180px) {
  .Testi-main {
    width: 90%;
    margin: 40px auto;
  }
  .Main-texthead {
    font-size: 40px;
    line-height: 49px;
  }
  .Main-Content {
    font-size: 16px;
    line-height: 22px;
  }
  .Clinet-name {
    font-size: 18px;
  }
  .Designation {
    font-size: 13px;
  }
}
@media all and (max-width: 880px) {
  .Testi-main {
    width: 90%;
    margin: 40px auto;
    display: block;
  }
  .Testi-img {
    width: 100%;
  }
  .Clint-testi {
    width: 100%;
  }
  .Testi-text {
    text-align: center;
  }
  .Main-texthead {
    text-align: center;
  }
}
@media all and (max-width: 550px) {
  .Clinet-content {
    width: 95%;
    margin: 10px auto;
  }
  .Freams {
    height: 260px;
  }
  .Main-texthead {
    font-size: 24px;
    line-height: 27px;
    margin-top: 5px;
  }
  .Testi-text {
    font-size: 16px;
    line-height: 18px;
  }
}
