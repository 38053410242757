body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  /* cursor: none; */
}
:root {
  --Font-Family: "Josefin Sans", sans-serif;
  --Yellow: #eb6f20;
  --Blue: #055393;
}
input:focus-visible {
  border: none;
}
input:focus {
  outline: none;
}
/* Custome ScrollBar */
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
  border: none;
}
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--Yellow);
}
/*Remove Type Number Arrows*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
