.Blog-Titels {
  font-family: var(--Font-Family);
  font-size: 42px;
  font-weight: 700;
  text-align: center;
  color: var(--Blue);
  margin: 15px 0px;
}
.Blog-Main {
  width: 90%;
  margin: 0 auto;
  display: flex;
  max-width: 1222px;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Card-Blog-mai {
  width: calc(33% - 10px);
  height: 350px;
  background-image: url(./IMG/MB1.png);
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
  object-fit: fill;
  background-size: cover;
  overflow: hidden;
  margin: 10px 0px;
}
.Card-Decored {
  width: 100%;
  height: 225px;
  position: absolute;
  background-image: url(./IMG/Intersect.svg);
  background-repeat: no-repeat;
  opacity: 75%;
  background-size: cover;
  bottom: 0px;
  transition: all 0.5s ease-in-out;
  object-fit: fill;
}
.Blog-Dates {
  font-family: var(--Font-Family);
  font-size: 16px;
  font-weight: 500;
  text-align: right;
  color: #fff;
  margin: 0px 20px;
  line-height: 10px;
  margin-bottom: 40px;
}
.subBlog-o2reanted {
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: all 0.5s ease-in-out;
}
.Read-more1 {
  color: var(--Yellow);
  font-family: var(--Font-Family);
  font-size: 16px;
  font-weight: 700;
  margin: 5px 20px;
  line-height: 5px;
  cursor: pointer;
  display: none;
  transition: all 0.5s ease-in-out;
}
.Read-more1:hover .ico3 {
  transform: translate(10px, 0);
}
.Text-Conpoblogs {
  font-family: var(--Font-Family);
  font-size: 25px;
  font-weight: 700;
  text-align: left;
  color: #fff;
  margin: 10px 20px;
  line-height: 25px;
  overflow: hidden;
  height: 48px;
}
.blogs-minit {
  font-family: var(--Font-Family);
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  color: #fff;
  margin: 10px 20px;
  line-height: 20px;
  height: 60px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}
.Card-Blog-mai:hover .Card-Decored {
  height: 350px;
}
.Card-Blog-mai:hover .blogs-minit {
  height: 160px;
}
.Card-Blog-mai:hover .Read-more1 {
  display: block;
}
@media all and (max-width: 1145px) {
  .Text-Conpoblogs {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 1px;
    height: 48px;
  }
  .Blog-Dates {
    margin-bottom: 30px;
  }
}
@media all and (max-width: 950px) {
  .Card-Blog-mai {
    width: calc(49% - 10px);
  }
}
@media all and (max-width: 750px) {
  .Card-Blog-mai {
    width: calc(50% - 10px);
  }
  .Blog-Main {
    width: 95%;
  }
  .Blog-Titels {
    font-size: 30px;
    margin: 15px 0px 0px 0px;
  }
}
@media all and (max-width: 650px) {
  .Text-Conpoblogs,
  .blogs-minit {
    margin: 10px;
  }
  .blogs-minit {
    height: 80px;
  }
  .Blog-Titels {
    font-size: 22px;
    margin: 15px 0px 0px 0px;
  }
  .Blog-Dates {
    margin-bottom: 25px;
    margin-right: 10px;
  }
}
@media all and (max-width: 550px) {
  .Blog-Dates {
    font-size: 14px;
  }
  .Blog-Dates {
    margin-bottom: 15px;
  }
  .Text-Conpoblogs {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 1px;
    height: 35px;
  }
  .blogs-minit {
    font-size: 13px;
    margin: 10px;
    line-height: 14px;
  }
  .Card-Blog-mai {
    height: 330px;
  }
}
@media all and (max-width: 500px) {
  .Card-Blog-mai {
    height: 250px;
  }
  .Blog-Dates {
    font-size: 10px;
  }
  .Text-Conpoblogs {
    font-size: 12px;
    line-height: 13px;
    letter-spacing: 0px;
    height: 25px;
  }
  .blogs-minit {
    font-size: 11px;
    line-height: 11px;
    height: 55px;
    margin: 5px 10px;
  }
  .Card-Blog-mai:hover .blogs-minit {
    height: 139px;
  }
  .Card-Decored {
    height: 150px;
  }
  .Read-more1 {
    font-size: 10px;
    margin: 5px 10px;
    line-height: 5px;
  }
}
