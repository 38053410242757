.Web-app {
  font-family: var(--Font-Family);
  color: var(--Blue);
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin-top: 20px;
}
.Port-Mian {
  width: 80%;
  height: auto;
  max-width: 1200px;
  margin: 20px auto 10px auto;
  /* border: 1px solid red; */
}
.Pro-titek {
  color: var(--Yellow);
  font-family: var(--Font-Family);
  font-size: 22px;
  margin: 0;
}
.Pro-description {
  font-size: 16px;
  font-family: var(--Font-Family);
  color: #2f2c2c;
  line-height: 23px;
  margin: 10px 0px;
}
.ImgLayout {
  height: 400px;
  width: 100%;
  object-fit: fill;
  background-size: cover;
  margin-top: 20px;
  margin-bottom: 20px;
}
.Title-Fetures {
  color: var(--Yellow);
  font-size: 20px;
  font-family: var(--Font-Family);
  font-weight: 500;
}
.Fetures-Maint {
  width: 80%;
  /* height: 400px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.Tab-feture {
  width: 40%;
  margin-top: 20px;
}
.Set-ups {
  font-family: var(--Font-Family);
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
}
.Nesda {
  font-family: var(--Font-Family);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  color: #2f2c2c;
}
.Tehnopp {
  color: #2f2c2c;
  font-size: 16px;
}
.Tech-icon {
  width: 100%;
  height: 300px;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.Maintent-class {
  width: 21%;
  height: 60px;
  margin: 0 auto;
  text-align: center;
}
.Icoosnic {
  margin: 0 auto;
  text-align: center;
  width: auto;
  height: 60px;
}
.Name-tecno {
  font-family: var(--Font-Family);
  color: #212529;
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
}
@media all and (max-width: 1100px) {
  .Port-Mian {
    width: 95%;
  }
  .Fetures-Maint {
    width: 100%;
  }
  .Tech-icon {
    margin-top: 20px;
  }
  .Icoosnic {
    height: 45px;
  }
}
@media all and (max-width: 900px) {
  .Icoosnic {
    height: 35px;
  }
  .Tab-feture {
    width: 45%;
    margin-top: 20px;
  }
  .Nesda {
    font-size: 14px;
    line-height: 19px;
  }
}
@media all and (max-width: 700px) {
  .Name-tecno {
    font-size: 15px;
    margin-top: 7px;
  }
  .Tab-feture {
    width: 60%;
    margin-top: 20px;
  }
  .Fetures-Maint {
    width: 100%;
    justify-content: center;
  }
  .Set-ups {
    text-align: center;
  }
  .Nesda {
    text-align: center;
  }
}
@media all and (max-width: 550px) {
  .Tech-icon {
    width: 100%;
    height: 190px;
    display: flex;
    flex-wrap: wrap;
  }
  .Tab-feture {
    width: 90%;
    margin-top: 20px;
  }
  .Icoosnic {
    height: 22px;
  }
  .Name-tecno {
    font-size: 11px;
    margin-top: 7px;
  }
}
