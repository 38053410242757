.Footer-Main {
  background-image: url(./IMG/Footer.svg);
  width: 100%;
  height: 450px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.Footer-boundry {
  max-width: 1200px;
  width: 90%;
  height: 320px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 70px;
  border-bottom: 2px solid #fff;
}
.F-Sections {
  width: 25%;
  height: 300px;
  /* border: 1px solid gray; */
  padding: 10px 20px;
}
.Footer-logo {
  width: 100%;
  height: 140px;
}
.Footer-text {
  font-family: var(--Font-Family);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-top: 10px;
  line-height: 25px;
}
.Heading-footer {
  font-family: var(--Font-Family);
  font-size: 34px;
  font-weight: 700;
  line-height: 25px;
  margin-top: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--Yellow);
}
.Heading-footer:after {
  background-color: #ffffff;
  bottom: -5px;
  height: 3px;
  width: 50px;
  position: relative;
  content: "";
  display: block;
}
.Fotter-contant {
  font-family: var(--Font-Family);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #fff;
  margin-top: 10px;
  line-height: 22px;
  margin-top: 20px;
}
.News-letter {
  display: flex;
}
.Email-input {
  border: 2px solid #fff;
  background: transparent;
  font-family: var(--Font-Family);
  font-size: 18px;
  padding: 10px;
  margin: 0;
  line-height: 0;
  display: flex;
  align-items: center;
  color: #fff;
}
.Email-input::placeholder {
  color: #fff;
  font-family: var(--Font-Family);
  font-size: 18px;
}
.SetBtn {
  background-color: #fff;
  color: var(--Blue);
  border: 2px solid #fff;
  padding: 7px 22px;
}
.Icon-btn {
  font-size: 22px;
  transition: 0.5s ease-in-out;
}
.Email-input:focus {
  border: 2px solid #fff;
}
.SetBtn:hover .Icon-btn {
  transform: translate(10px, -10px);
}
.Flexi-icons {
  display: flex;
  margin-top: 15px;
}
.Social-Icons {
  font-size: 25px;
  color: #fff;
  margin-right: 10px;
  cursor: pointer;
}
.Footer-Contact {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.Footer-Contact1 {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.Footer-textcontact {
  font-family: var(--Font-Family);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #fff !important;
  line-height: 25px;
  margin: 0;
  text-decoration: none;
}
.location-icon {
  font-size: 40px;
  color: #fff;
  margin-right: 10px;
}
.location-phone {
  font-size: 25px;
  color: #fff;
  margin-right: 10px;
}
.Sub-head {
  font-family: var(--Font-Family);
  font-size: 22px;
  font-weight: 700;
  color: var(--Yellow);
  margin-top: 20px;
  margin-bottom: 0;
}
.Footer-timeing {
  font-family: var(--Font-Family);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #fff !important;
  line-height: 30px;
  margin: 0;
}
.Gallery-Main {
  margin-top: 20px;
}
.Gallery-Imgs {
  width: 100%;
  display: flex;
}
.G-Img {
  height: 100px;
  width: 100px;
  margin: 5px;
}
.Copyright {
  color: #fff;
  font-size: 18px;
  font-family: var(--Font-Family);
  text-align: center;
  margin: 10px 0px 0px 0px;
}
@media all and (max-width: 1300px) {
  .Footer-boundry {
    width: 95%;
  }
}
@media all and (max-width: 1200px) {
  .Footer-logo {
    height: 80px;
  }
  .Footer-text {
    font-size: 16px;
    line-height: 20px;
  }
  .Heading-footer {
    font-size: 26px;
  }
  .Fotter-contant {
    font-size: 16px;
    line-height: 18px;
  }
  .Email-input {
    font-size: 16px;
  }
  .Social-Icons {
    font-size: 21px;
  }
  .Footer-textcontact {
    font-size: 16px;
    line-height: 20px;
  }
  .location-phone {
    font-size: 21px;
  }
  .Footer-timeing {
    font-size: 16px;
    line-height: 22px;
  }
  .G-Img {
    height: 80px;
    width: 80px;
  }
}
@media all and (max-width: 1070px) {
  .Footer-boundry {
    width: 100%;
    padding-top: 20px;
    height: 280px;
  }
  .F-Sections {
    padding: 10px 6px;
    height: 260px;
  }
  .G-Img {
    height: 70px;
    width: 70px;
  }
  .Footer-Main {
    height: 350px;
  }
  .Copyright {
    font-size: 16px;
  }
}
@media all and (max-width: 940px) {
  .Footer-boundry {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .F-Sections {
    padding: 0px;
    height: 260px;
    width: 40%;
  }
  .Footer-Main {
    height: auto;
  }
  .Footer-boundry {
    height: auto;
  }
  .Footer-logo {
    width: 50%;
    height: 80px;
  }
  .F-Sections {
    padding: 0px;
    height: auto;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 40%;
  }
}
@media all and (max-width: 650px) {
  .Email-input {
    font-size: 15px;
  }
  .Email-input::placeholder {
    font-size: 15px;
  }
}
@media all and (max-width: 570px) {
  .F-Sections {
    width: 90%;
  }
  .Footer-logo {
    width: 100%;
    height: 90px;
  }
  .Footer-text {
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }
  .location-icon {
    font-size: 22px;
    color: #fff;
    margin-right: 10px;
  }
  .Fotter-contant {
    font-size: 18px;
    line-height: 18px;
  }
  .Footer-textcontact {
    font-size: 18px;
    line-height: 20px;
  }
  .Footer-timeing {
    font-size: 18px;
    line-height: 22px;
  }
  .G-Img {
    height: 100px;
    width: 100px;
  }
}
