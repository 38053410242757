.About-main {
  width: 100%;
  position: relative;
}
.B-img {
  width: 100%;
  height: 280px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.Banner-Title {
  position: absolute;
  text-align: center;
  color: #fff;
  font-size: 30px;
  font-family: var(--Font-Family);
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
}
@media all and (max-width: 1200px) {
  .B-img {
    height: 240px;
  }
}
@media all and (max-width: 992px) {
  .B-img {
    height: 130px;
  }
  .Banner-Title {
    font-size: 27px;
    top: 50%;
    left: 50%;
  }
}
@media all and (max-width: 650px) {
  .B-img {
    height: 90px;
  }
  .Banner-Title {
    font-size: 20px;
    top: 50%;
    left: 50%;
  }
}
@media all and (max-width: 550px) {
  .B-img {
    height: 70px;
  }
}
