.Main-steps {
  width: 100%;
}

.steps-img-container {
  position: relative;
  width: 100%;
  height: 550px;
}

.steps-img {
  width: 100%;
  height: 100%;
  background-image: url(../IMG/Section.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.steps-img-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #055393b5;
}
.W-steps {
  font-family: var(--Font-Family);
  color: #fff;
  font-size: 20px;
  position: relative;
  text-align: center;
  margin-bottom: 0;
  padding-top: 30px;
}
.Easy-steps {
  font-family: var(--Font-Family);
  color: #fff;
  font-size: 70px;
  position: relative;
  text-align: center;
  padding-top: 0px;
  font-weight: 600;
  margin-top: 0;
}
.Steps-box {
  width: 80%;
  height: 300px;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
}
.S-Box {
  width: 25%;
  /* height: 200px; */
  /* border: 2px solid red; */
  position: relative;
}
.Process-no {
  z-index: 10;
  font-family: var(--Font-Family);
  color: var(--Yellow);
  font-size: 90px;
  font-weight: 700;
  text-align: center;
}
.Step-gif {
  width: 40px;
  height: 40px;
  background-color: var(--Blue);
  padding: 6px;
  border-radius: 100%;
  border: 1px solid var(--Yellow);
  position: absolute;
  top: 20px;
  left: 70px;
}
.P-name {
  font-family: var(--Font-Family);
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  margin-top: -35px;
}
.P-Content {
  font-family: var(--Font-Family);
  font-size: 16px;
  font-weight: 500;
  line-height: 15px;
  text-align: center;
  color: #fff;
  margin-top: -15px;
  padding: 0px 30px;
}
@media all and (max-width: 1300px) {
  .Steps-box {
    width: 90%;
  }
  .Steps-box {
    width: 95%;
  }
  .Easy-steps {
    font-size: 45px;
  }
  .W-steps {
    font-size: 16px;
  }
  .Process-no {
    font-size: 70px;
  }
  .Step-gif {
    width: 30px;
    height: 30px;
    top: 10px;
    left: 80px;
  }
  .P-Content {
    font-size: 14px;
    line-height: 15px;
    margin-top: -15px;
    padding: 0px 20px;
  }
  .steps-img-container {
    height: 390px;
  }
}
@media all and (max-width: 1170px) {
  .Step-gif {
    width: 30px;
    height: 30px;
    top: 0px;
    left: 65px;
  }
}
@media all and (max-width: 1070px) {
  .P-Content {
    padding: 0px 10px;
  }
  .Step-gif {
    top: 0px;
    left: 55px;
  }
  .steps-img-container {
    height: auto;
  }
}
@media all and (max-width: 950px) {
  .Step-gif {
    top: 0px;
    left: 129px;
  }
  .Steps-box {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .S-Box {
    width: 45%;
    position: relative;
  }
  .steps-img-container {
    height: 570px;
  }
  .Easy-steps {
    font-size: 40px;
    line-height: 30px;
  }
  .Steps-box {
    margin-top: 20px;
  }
}
@media all and (max-width: 850px) {
  .Step-gif {
    top: 0px;
    left: 110px;
  }
}
@media all and (max-width: 750px) {
  .Step-gif {
    top: 0px;
    left: 85px;
  }
}
@media all and (max-width: 650px) {
  .S-Box {
    width: 48%;
    position: relative;
  }
  .Process-no {
    font-size: 60px;
  }
  .Step-gif {
    width: 20px;
    height: 20px;
  }
  .Step-gif {
    top: 7px;
    left: 90px;
    width: 15px;
    height: 15px;
  }
}
@media all and (max-width: 550px) {
  .Step-gif {
    top: 7px;
    left: 60px;
  }
  .S-Box {
    width: 49%;
    position: relative;
  }
  .Process-no {
    font-size: 50px;
  }
  .P-name {
    font-size: 20px;
  }
  .P-Content {
    font-size: 12px;
    line-height: 12px;
  }
  .steps-img-container {
    height: 470px;
  }
  .Easy-steps {
    font-size: 30px;
    line-height: 30px;
  }
}
@media all and (max-width: 430px) {
  .P-Content {
    padding: 0px 5px;
  }
  .steps-img-container {
    height: 500px;
  }
  .Step-gif {
    top: 7px;
    left: 50px;
  }
}
