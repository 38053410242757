.AB-Container {
  width: 80%;
  margin: 30px auto 0px auto;
  display: flex;
  max-width: 1200px;
}
.Context-AB {
  width: 50%;
}
.text-AB {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  font-family: var(--Font-Family);
  letter-spacing: 1px;
}
.TextM-AB {
  font-family: var(--Font-Family);
  color: var(--Blue);
  font-size: 55px;
  font-weight: 600;
  align-items: center;
  line-height: 64px;
}
.Subtext-AB {
  font-family: var(--Font-Family);
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  padding: 0px 55px 0px 0px;
}
.The-Best {
  height: 400px;
  width: 95%;
}
.MMV-head {
  font-family: var(--Font-Family);
  font-size: 42px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--Blue);
  margin-top: 30px;
}
.MVV {
  width: 80%;
  margin: 20px auto 20px auto;
  display: flex;
  justify-content: space-around;
  max-width: 1200px;
}
.MVV-Conteainer {
  width: 30%;
}
.MMV-GIF {
  width: 150px;
  height: 150px;
  display: flex;
  margin: 0 auto;
}
.Big-text-MMV {
  font-family: var(--Font-Family);
  color: var(--Blue);
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  margin: 0;
  line-height: 20px;
}
.MMV-content {
  font-family: var(--Font-Family);
  color: #333;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
  padding: 0px 10px;
}
.Line-MMV {
  width: 55%;
  margin: 10px auto 20px auto;
  display: flex;
}
@media all and (max-width: 1300px) {
  .AB-Container {
    width: 90%;
  }
  .text-AB {
    font-size: 17px;
  }
  .TextM-AB {
    font-size: 45px;
    line-height: 50px;
  }
  .Subtext-AB {
    font-size: 16px;
    line-height: 23px;
    padding: 0px 35px 0px 0px;
  }
  .The-Best {
    height: 350px;
    margin: 0 auto;
    width: 80%;
    display: flex;
  }
  .MVV {
    width: 90%;
  }
  .MMV-head {
    font-size: 35px;
  }
  .MMV-GIF {
    width: 100px;
    height: 100px;
  }
  .Big-text-MMV {
    font-size: 24px;
    line-height: 20px;
  }
  .MMV-content {
    font-size: 16px;
    line-height: 20px;
  }
  .MVV-Conteainer {
    width: 32%;
  }
}
@media all and (max-width: 950px) {
  .TextM-AB {
    font-size: 30px;
    line-height: 30px;
  }
  .Subtext-AB {
    font-size: 16px;
    line-height: 23px;
    padding: 0px;
  }
  .MMV-GIF {
    width: 80px;
    height: 80px;
  }
  .Big-text-MMV {
    font-size: 20px;
    line-height: 20px;
  }
  .Line-MMV {
    width: 46%;
    margin: 0px auto 20px auto;
  }
  .MVV {
    width: 95%;
  }
}
@media all and (max-width: 800px) {
  .AB-Container {
    width: 95%;
  }
  .MVV {
    display: flex;
    flex-wrap: wrap;
  }
  .MVV-Conteainer {
    width: 60%;
  }
  .MMV-head {
    font-size: 27px;
  }
  .Line-MMV {
    width: 30%;
    margin: 0px auto 20px auto;
  }
}
@media all and (max-width: 700px) {
  .AB-Container {
    display: block;
    width: 95%;
  }
  .Context-AB {
    width: 100%;
  }
  .The-Best {
    height: 300px;
  }

  .Context-AB {
    width: 100%;
    text-align: center;
  }
  .MVV-Conteainer {
    width: 90%;
  }
}
@media all and (max-width: 550px) {
  .TextM-AB {
    font-size: 24px;
    line-height: 30px;
  }
  .The-Best {
    height: 210px;
  }
  .Subtext-AB {
    font-size: 15px;
    line-height: 19px;
    padding: 0px;
  }
  .MMV-head {
    font-size: 23px;
  }
}
