.Ser-Con {
  width: 80%;
  margin: 0 auto;
  max-width: 1200px;
}
.Main-write {
  font-family: var(--Font-Family);
  font-size: 35px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--Blue);
  margin-top: 35px;
}
.Stage-Compo {
  width: 100%;
  display: flex;
  margin-top: 30px;
}
.Satge-content {
  width: 50%;
}
.Stage-Img {
  width: 50%;
}
.line-stages {
  font-family: var(--Font-Family);
  color: var(--Blue);
  font-size: 30px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  padding: 10px 100px 0px 0px;
}
.lining-stageing {
  font-family: var(--Font-Family);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  padding: 0px 40px 0px 0px;
}
.Main-Uiux {
  width: 90%;
  height: 280px;
  margin: 0 auto;
  display: flex;
}
.Cards-main {
  width: 90%;
  margin: 60px auto 0px auto;
  display: flex;
  justify-content: space-around;
}
.Card-UiUx {
  width: 25%;
  height: auto;
  border: 1px solid #aaaaaa;
  border-radius: 10px;
  padding: 20px;
}
.Gif-animat {
  width: 100px;
  height: 100px;
}
.Text-vuy {
  font-family: var(--Font-Family);
  font-size: 20px;
  font-weight: 700;
  line-height: 26px;
  letter-spacing: 0em;
  margin: 0;
  text-align: left;
  color: var(--Blue);
}
.Line-img {
  margin: 15px 0px;
}
.Textnow {
  font-family: var(--Font-Family);
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
.Acomplesh {
  margin-top: 30px;
  text-align: center;
  font-family: var(--Font-Family);
  color: var(--Blue);
  font-size: 30px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 0em;
}
.proficient {
  width: 100%;
  display: flex;
}
.profic {
  margin-top: 20px;
  width: 50%;
}
.Uxdjw {
  width: 90%;
  height: 420px;
  margin: 10px auto;
  display: flex;
}
.Texticjh {
  font-family: var(--Font-Family);
  color: var(--Blue);
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: left;
  padding: 20px 70px 20px 20px;
}
.Textarea918 {
  font-family: var(--Font-Family);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  padding: 10px 20px 0px 20px;
}
.Ubtn {
  color: #fff;
  font-family: var(--Font-Family);
  font-size: 18px;
  background-color: var(--Yellow);
  border: 1px solid var(--Yellow);
  border-radius: 10px;
  margin: 20px;
  display: flex;
  padding: 5px 25px;
  line-height: 30px;
  align-items: center;
}
@media all and (max-width: 1200px) {
  .Ser-Con {
    width: 90%;
  }
  .Main-write {
    font-size: 30px;
  }
  .line-stages {
    font-size: 25px;
    line-height: 30px;
  }
  .lining-stageing {
    font-size: 14px;
    line-height: 20px;
  }
  .Main-Uiux {
    width: 90%;
    height: 220px;
  }
  .Gif-animat {
    width: 80px;
    height: 80px;
  }
  .Text-vuy {
    font-size: 18px;
    line-height: 23px;
  }
  .Textnow {
    font-size: 15px;
    line-height: 22px;
  }
  .Texticjh {
    font-size: 25px;
    line-height: 30px;
  }
  .Uxdjw {
    width: 90%;
    height: 335px;
  }
  .Textarea918 {
    font-size: 14px;
    line-height: 18px;
  }
  .Ubtn {
    font-size: 15px;
    margin: 20px;
    padding: 5px 15px;
    line-height: 26px;
  }
}
@media all and (max-width: 850px) {
  .Cards-main {
    width: 100%;
  }
  .Ser-Con {
    width: 95%;
  }
  .Card-UiUx {
    width: 27%;
  }
}
@media all and (max-width: 750px) {
  .Main-write {
    font-size: 25px;
    line-height: 30px;
  }
  .Stage-Compo {
    display: block;
  }
  .line-stages {
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    padding: 0;
  }
  .Satge-content {
    width: 100%;
    margin: 0 auto;
  }
  .lining-stageing {
    font-size: 14px;
    padding: 0;
    text-align: center;
    line-height: 20px;
  }
  .Stage-Img {
    width: 100%;
  }
  .Cards-main {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
  .Card-UiUx {
    width: 95%;
    margin: 10px 0px;
  }
  .proficient {
    display: block;
  }
  .profic {
    margin-top: 20px;
    width: 100%;
  }
  .Texticjh {
    padding: 0;
  }
  .Textarea918 {
    padding: 0;
  }
  .Ubtn {
    margin: 20px 0px;
  }
}
@media all and (max-width: 570px) {
  .Main-write {
    font-size: 20px;
    line-height: 25px;
  }
  .line-stages {
    font-size: 20px;
    line-height: 25px;
  }
  .Acomplesh {
    margin-top: 20px;
    font-size: 20px;
    line-height: 28px;
  }
  .Uxdjw {
    width: 90%;
    height: 260px;
  }
  .Texticjh {
    font-size: 20px;
    line-height: 25px;
  }
}
