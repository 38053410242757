.Blog-Conteat {
  width: 85%;
  margin: 30px auto;
  max-width: 1200px;
}
.b-head {
  font-size: 30px;
  font-family: var(--Font-Family);
  color: var(--Blue);
  line-height: 20px;
  font-weight: 700;
  margin: 0;
  padding: 0;
}
.Icon-arrow {
  color: var(--Yellow);
  font-size: 35px;
  font-weight: 700;
  align-items: center;
  cursor: pointer;
}
.St-fblog {
  width: 100%;
  display: block;
}
.st-subhead {
  color: var(--Blue);
  font-family: var(--Font-Family);
  font-size: 28px;
  font-weight: 600;
}
.st-img {
  width: 100%;
  height: 350px;
  margin: 20px 0px;
}
.St-common {
  font-family: var(--Font-Family);
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #3b3c4a;
}
.Teg-line {
  width: 50%;
  margin: 30px auto;
  height: 100px;
  border-radius: 10px;
  background-color: #f5b790;
  border-left: 5px solid #eb6f20;
  display: flex;
  align-items: center;
}
.line-style {
  font-family: Source Serif Pro;
  font-size: 19px;
  font-style: italic;
  font-weight: 500;
  text-align: center;
  margin: 0;
  padding: 0px 20px;
  line-height: 30px;
  color: #181a2a;
  letter-spacing: 1px;
}
.Dateset {
  color: #696a75;
  font-family: var(--Font-Family);
  font-size: 1rem;
  text-align: right;
  margin: 0;
}
@media all and (max-width: 1300px) {
  .Blog-Conteat {
    width: 95%;
    margin: 30px auto;
    max-width: 1200px;
  }
  .b-head {
    font-size: 25px;
  }
  .Icon-arrow {
    font-size: 30px;
  }
  .St-common {
    font-size: 16px;
    line-height: 21px;
  }
  .st-subhead {
    font-size: 22px;
  }
  .line-style {
    font-size: 16px;
    line-height: 26px;
  }
  .Teg-line {
    height: 90px;
  }
}
@media all and (max-width: 850px) {
  .b-head {
    font-size: 19px;
  }
  .line-style {
    font-size: 13px;
    line-height: 19px;
  }
  .Teg-line {
    width: 90%;
  }
  .line-style {
    font-size: 15px;
    line-height: 19px;
  }
}
