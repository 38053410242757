.Counter-Main {
  width: 80%;
  height: 220px;
  max-width: 1200px;
  margin: 80px auto 100px auto;
  background-image: url(./IMG/Counter.svg);
  background-size: cover;
  object-fit: fill;
  background-repeat: no-repeat;
}
.Counter-TEXT {
  font-family: var(--Font-Family);
  font-size: 60px;
  font-weight: 700;
  line-height: 60px;
  text-align: center;
  color: #fff;
  padding-top: 30px;
}
.Counter-Data {
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin: 1rem auto;
}
.Sub-Count {
  text-align: center;
  display: block;
}
.counter {
  font-family: var(--Font-Family);
  color: #fff;
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0em;
}
.Count-pulsh {
  display: flex;
  justify-content: center;
}
.Count-line {
  font-family: var(--Font-Family);
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
}
.plush {
  font-family: var(--Font-Family);
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  color: var(--Yellow);
  display: flex;
  align-items: center;
  margin: 0px 5px;
}
.k0 {
  font-family: var(--Font-Family);
  font-size: 40px;
  font-weight: 700;
  line-height: 40px;
  color: #fff;
  display: flex;
  align-items: center;
  margin: 0px 5px;
}
@media all and (max-width: 1300px) {
  .Counter-Main {
    width: 90%;
  }
}
@media all and (max-width: 980px) {
  .Counter-TEXT {
    font-size: 35px;
  }
  .counter {
    font-size: 25px;
  }
  .plush {
    font-size: 25px;
  }
  .k0 {
    font-size: 25px;
  }
  .Count-line {
    font-size: 16px;
  }
  .Counter-Data {
    width: 100%;
  }
}
@media all and (max-width: 700px) {
  .Counter-Main {
    width: 90%;
    margin: 20px auto;
    height: 116px;
  }
  .Counter-TEXT {
    font-size: 25px;
    padding: 0;
    line-height: 43px;
    margin: 0;
  }
  .Counter-Data {
    margin: 0rem auto;
  }
}
@media all and (max-width: 550px) {
  .Counter-Main {
    margin: 60px auto 0px auto;
  }
  .Counter-TEXT {
    font-size: 19px;
  }
  .counter {
    font-size: 18px;
  }
  .Count-line {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
  }
  .k0 {
    font-size: 18px;
  }
  .counter {
    font-size: 18px;
    text-align: center;
  }
}
