.Vnjf02346 {
  display: flex !important;
  height: 350px;
  width: 100% !important;
  justify-content: center;
  max-width: 1200px;
}

.Slider-Main {
  margin-top: 20px;
  margin-bottom: 40px;
}

.jsg0972876 {
  width: 100%;
  position: relative;
  cursor: pointer;
}

.SLiDeR1 {
  height: 300px;
  width: 400px;
}

.DHgd09727 {
  text-align: center;
  text-transform: uppercase;
  color: #2f7175;
  font-family: "Lato";
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 110px;
  font-size: 30px;
}

.slick-slider {
  margin: 0 auto;
  width: 100%;
}

.Card-Slider {
  position: absolute;
  bottom: 70px;
  left: 20px;
  font-family: var(--Font-Family);
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}

.Main-CSlider {
  position: absolute;
  bottom: 40px;
  left: 20px;
  font-family: var(--Font-Family);
  font-size: 24px;
  font-weight: 700;
  color: #fff;
}

.JDGdfg {
  width: 70%;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.FDujfgy96234 {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #2f7175;
  padding: 0px 80px;
}

.Fgopdf7109ZZ {
  display: flex;
  justify-content: right;
}

.slick-dots li.slick-active button:before {
  color: var(--Yellow) !important;
  font-size: 315px !important;
  opacity: 10 !important;
  font-weight: 100;
  top: -112px;
  transition: 1s ease-in-out;
}

.slick-dots li button::before {
  color: #fff !important;
  font-size: 315px !important;
  opacity: 10 !important;
  content: "_" !important;
  font-weight: 100;
  top: -196px !important;
}

.slick-list {
  overflow: visible !important;
  overflow-x: clip !important;
}

.slick-dots li {
  width: 150px !important;
}

.slick-dots li button {
  position: absolute !important;
  height: 10px !important;
  width: 160px !important;
}

.slick-dots {
  height: 8px !important;
  overflow: hidden;
}

/* slider.css */
.swiper-pagination-bullet {
  width: 20px;
  height: 5px;
  background-color: #000;
  /* Default color */
  border-radius: 0;
  /* Make it a rectangle */
  margin: 5px !important;
  /* Adjust spacing if needed */
}

.swiper-pagination-bullet-active {
  background-color: #f00;
  /* Active color */
}

@media all and (max-width: 1400px) {
  .SLiDeR1 {
    height: 250px;
    width: 300px;
  }

  .Main-CSlider {
    font-size: 17px;
  }

  .Card-Slider {
    bottom: 30px;
  }

  .Main-CSlider {
    bottom: 17px;
  }

  .Vnjf02346 {
    height: 260px;
  }

  .Portfolio-Main {
    height: 500px;
  }
}

@media all and (max-width: 1070px) {
  .SLiDeR1 {
    height: 200px;
    width: 270px;
  }

  .Vnjf02346 {
    height: 220px;
  }
}

@media all and (max-width: 900px) {
  .SLiDeR1 {
    height: 180px;
    width: 250px;
  }

  .Vnjf02346 {
    height: 200px;
  }

  .Classical {
    font-size: 16px;
    padding-top: 10px;
  }

  .Head-type {
    font-size: 39px;
    margin-top: 0px;
  }

  .Portfolio-Main {
    height: 360px;
  }

  .Slider-Main {
    height: 250px !important;
  }
}

@media all and (max-width: 600px) {
  .SLiDeR1 {
    height: 160px;
    width: 230px;
  }

  .Vnjf02346 {
    height: 180px;
  }

  .Card-Slider {
    font-size: 13px;
  }

  .Main-CSlider {
    font-size: 15px;
  }

  .Head-type {
    font-size: 30px;
  }
}

@media all and (max-width: 530px) {
  .SLiDeR1 {
    height: 140px;
    width: 210px;
  }

  .Vnjf02346 {
    height: 160px;
  }

  .text-content {
    width: 90%;
  }
}

@media all and (max-width: 470px) {
  .Portfolio-Main {
    height: 330px;
  }
}