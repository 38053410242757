.centerer *,
.centerer *:before,
.centerer *:after {
  box-sizing: border-box;
}

.centerer span {
  transition: none;
}

.centerer a {
  text-decoration: none;
  color: black;
  transition: 0.5s ease-in-out;
}

.centerer [class^="btn-"] {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 45px;
  /* max-width: 130px; */
  margin: 1rem auto;
  /* text-transform: uppercase; */
  border: 2px solid #ffffff;
  border-radius: 10px;
}

.centerer .btn-6 {
  color: #ffffff !important;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding: 10px 13px;
  align-items: center;
  display: flex;
  background-color: #eb6f20;
  z-index: 99;
  position: relative;
}

.centerer .btn-6 span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: #055393;
  transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.centerer .btn-6:hover {
  color: #eb6f20;
}

.centerer .btn-6:hover span {
  width: 225%;
  height: 562.5px;
}

.centerer .btn-6:active {
  background-color: #956a87;
}
