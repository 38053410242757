.D-header {
  font-family: var(--Font-Family);
  color: var(--Blue);
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  margin: 20px 0px;
}

.D-card {
  width: 80%;
  max-width: 1200px;
  height: 500px;
  margin: 1rem auto;
  display: flex;
  justify-content: space-around;
}
.Card-Director1 {
  width: 28%;
  min-height: 450px;
  background-image: url(./IMG/Director-1.svg);
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  transition: all 1s ease-in-out;
  cursor: pointer;
  object-fit: fill;
  background-size: contain;
}
.Card-Director2 {
  width: 28%;
  min-height: 450px;
  background-image: url(./IMG/Director-2.svg);
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  transition: all 1s ease-in-out;
  cursor: pointer;
  object-fit: fill;
  background-size: cover;
}
.Card-Director3 {
  width: 28%;
  min-height: 450px;
  background-image: url(./IMG/Director-3.svg);
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  transition: all 1s ease-in-out;
  cursor: pointer;
  object-fit: fill;
  background-size: cover;
}
.Card-decorec {
  width: 100%;
  height: 177px;
  position: absolute;
  background-image: url(./IMG/Intersect.svg);
  background-repeat: no-repeat;
  opacity: 75%;
  bottom: 0px;
  transition: all 0.5s ease-in-out;
}

.Text-Conpo {
  font-family: var(--Font-Family);
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  margin: 0px;
  line-height: 25px;
}
.Text-Conpo1 {
  font-family: var(--Font-Family);
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: var(--Yellow);
  margin: 0px 0px 30px 0px;
}
.sub-oreanted {
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: all 0.5s ease-in-out;
}
.Icosns {
  display: flex;
  position: absolute;
  bottom: -16px;
  overflow: visible;
  transition: all 0.5s ease-in-out;
}
.Icon-chges {
  color: #747474;
  background-color: #fff;
  font-size: 24px;
  padding: 7px;
  transition: all 0.5s ease-in-out;
  border-left: 2px solid #e9e6e6;
}
.Card-Director1:hover .Card-decorec {
  height: 260px;
}
.Card-Director1:hover .sub-oreanted {
  bottom: 60px;
}
.Card-Director1:hover .Icosns {
  bottom: 30px;
}
.Card-Director1:hover .Icon-chges {
  margin: 0px 5px;
}
/* Second Effect */
.Card-Director2:hover .Card-decorec {
  height: 260px;
}
.Card-Director2:hover .sub-oreanted {
  bottom: 60px;
}
.Card-Director2:hover .Icosns {
  bottom: 30px;
}
.Card-Director2:hover .Icon-chges {
  margin: 0px 5px;
}
/* Third Effect */
.Card-Director3:hover .Card-decorec {
  height: 260px;
}
.Card-Director3:hover .sub-oreanted {
  bottom: 60px;
}
.Card-Director3:hover .Icosns {
  bottom: 30px;
}
.Card-Director3:hover .Icon-chges {
  margin: 0px 5px;
}
/* Only Icon Hover */
.Icon-chges:hover {
  color: var(--Blue);
  border-left: 4px solid var(--Yellow);
}
@media all and (max-width: 1300px) {
  .D-card {
    width: 100%;
  }
}
@media all and (max-width: 1050px) {
  .Card-Director1 {
    width: 33%;
  }
  .Card-Director3 {
    width: 33%;
  }
  .D-header {
    font-size: 35px;
  }
  .D-card {
    width: 100%;
    height: 420px;
  }
}
@media all and (max-width: 830px) {
  .Card-Director3 {
    width: 40%;
  }
  .Card-Director1 {
    width: 40%;
  }
  .D-header {
    font-size: 30px;
  }
}
@media all and (max-width: 750px) {
  .Card-Director3,
  .Card-Director1 {
    min-height: auto;
    height: 400px;
  }
}
@media all and (max-width: 650px) {
  .Card-Director3,
  .Card-Director1 {
    min-height: auto;
    height: 376px;
    width: 45%;
  }
  .Text-Conpo {
    font-size: 20px;
  }
  .Text-Conpo1 {
    font-size: 16px;
  }
  .Icon-chges {
    font-size: 18px;
  }
}
@media all and (max-width: 550px) {
  .D-card {
    width: 100%;
    height: auto;
    display: block;
  }
  .Card-Director3,
  .Card-Director1 {
    height: 376px;
    width: 60%;
    margin: 30px auto;
  }
}
@media all and (max-width: 450px) {
  .Card-Director3,
  .Card-Director1 {
    height: 376px;
    width: 80%;
    margin: 30px auto;
  }
}
