.BannerVid {
  margin-top: 3rem;
  width: 100%;
}
.videoTag {
  width: 100%;
  height: 695px;
  background-size: contain;
  object-fit: cover;
}
.Main-Container {
  max-width: 1200px;
  width: 70%;
  margin: -100px auto 30px auto;
  display: flex;
  justify-content: space-between;
}
.Card {
  width: 23%;
  height: 280px;
  background: #fff;
  border-radius: 8px;
  z-index: 10;
  transition: 0.3s ease-in-out;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.092) !important;
}
.Card:hover {
  background: var(--Blue);
}
.Card:hover .IconsImg1 {
  background-image: url(./IMG/White1.svg);
}
.Card:hover .IconsImg2 {
  background-image: url(./IMG/White2.svg);
}
.Card:hover .IconsImg3 {
  background-image: url(./IMG/white3.svg);
}
.Card:hover .IconsImg4 {
  background-image: url(./IMG/white4.svg);
}
.Card:hover .Card-text {
  color: #fff;
}
.Card:hover .Card-Subtext {
  color: #fff;
}
.Card:hover .Read-more {
  color: #fff;
}
.Read-more:hover {
  cursor: pointer;
  /* text-decoration: underline; */
}
.ico3 {
  transition: 0.3s ease-in-out;
}
.Read-more:hover .ico3 {
  transform: translate(10px, 0);
}

.IconsImg1 {
  background-image: url(./IMG/Card-1.svg);
  height: 60px;
  width: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: fill;
  transition: 0.3s ease-in-out;
}
.IconsImg2 {
  background-image: url(./IMG/Card-2.svg);
  height: 60px;
  width: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: fill;
  transition: 0.3s ease-in-out;
}
.IconsImg3 {
  background-image: url(./IMG/Card-3.svg);
  height: 60px;
  width: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: fill;
  transition: 0.3s ease-in-out;
}
.IconsImg4 {
  background-image: url(./IMG/Card-4.svg);
  height: 60px;
  width: 60px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: fill;
  transition: 0.3s ease-in-out;
}
.Cardsetups {
  margin: 20px 18px;
  transition: 0.3s ease-in-out;
}
.Card-text {
  font-family: var(--Font-Family);
  font-size: 18px;
  font-weight: 600;
  color: var(--Yellow);
  line-height: 24px;
  margin: 20px 0px 0px 0px;
  letter-spacing: 1px;
  transition: 0.3s ease-in-out;
}
.Card-Subtext {
  font-family: var(--Font-Family);
  font-size: 14px;
  font-weight: 500;
  color: #000;
  line-height: 17px;
  margin: 20px 0px 0px 0px;
  letter-spacing: 1px;
  transition: 0.3s ease-in-out;
}
.Read-more {
  font-family: var(--Font-Family);
  font-size: 16px;
  font-weight: 500;
  color: var(--Blue);
  line-height: 17px;
  margin: 20px 0px 0px 0px;
  letter-spacing: 1px;
  transition: 0.3s ease-in-out;
}
.Main-Agency {
  max-width: 1200px;
  width: 80%;
  margin: 60px auto 30px auto;
  display: flex;
  justify-content: space-between;
}
.Agency-img {
  width: 50%;
}
.Agency-content {
  width: 50%;
  display: block;
}
.Work-men {
  height: 490px;
  width: 90%;
}
.flag-animation {
  height: 30px;
  width: 30px;
}
.Agency-text {
  font-family: var(--Font-Family);
  color: var(--Blue);
  font-size: 20px;
  font-weight: 500;
  /* align-items: center; */
  /* display: flex; */
  margin: 40px 0px 0px 0px;
  line-height: 20px;
}
.Main-textas {
  font-family: var(--Font-Family);
  color: var(--Blue);
  font-size: 55px;
  font-weight: 600;
  align-items: center;
  line-height: 64px;
  margin: 40px 0px 0px 0px;
  padding: 0px 22px 0px 0px;
}
.Get-Btn {
  color: #fff;
  background-color: var(--Yellow);
  font-family: var(--Font-Family);
  font-size: 20px;
  font-weight: 500;
  border: 1px solid var(--Yellow);
  padding: 5px 20px;
  border-radius: 7px;
  margin-top: 30px;
  transition: 0.3s ease-in-out;
}
.Get-Btn:hover {
  color: #fff;
  background-color: var(--Blue);
  border-color: var(--Blue);
}

.Setps-process {
  max-width: 1200px;
  width: 80%;
  height: auto;
  position: relative;
  margin: 50px auto 0px auto;
  display: flex;
  justify-content: center;
}
.Service-main {
  max-width: 1200px;
  width: 80%;
  margin: 10px auto;
}
.Service-text {
  font-family: var(--Font-Family);
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  text-align: center;
  color: #000;
  margin-top: 50px;
}
.Service-Subbloder {
  font-family: var(--Font-Family);
  font-size: 50px;
  font-weight: 600;
  line-height: 55px;
  text-align: center;
  color: var(--Blue);
  margin-top: 30px;
}
.mainblock-service {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
}
.block-container {
  width: 33.33%;
}
.Divided-block {
  display: flex;
  width: 100%;
  padding: 0;
  margin: 0;
}
.block-gif {
  width: 25%;
  margin-top: 26px;
}
.gif-ser {
  width: 60px;
  height: 60px;
  margin: 0 20%;
}
.Block-Containt {
  width: 75%;
  display: block;
  /* border: 1px solid blue; */
}
.text-main-ser {
  font-family: var(--Font-Family);
  font-size: 24px;
  font-weight: 700;
  color: var(--Blue);
  margin-top: 20px;
  margin-left: 5px;
}
.subtext-main-ser {
  font-family: var(--Font-Family);
  font-size: 16px;
  font-weight: 700;
  color: #000;
  height: 60px;
  margin: 5px;
  line-height: 20px;
}
.Discover-More {
  font-family: var(--Font-Family);
  font-size: 16px;
  font-weight: 700;
  color: var(--Yellow);
  margin-top: 20px;
  line-height: 20px;
}
.icoDes {
  transition: 0.3s ease-in-out;
}
.Block-Containt:hover .icoDes {
  transform: translate(10px, 0);
}
@media all and (max-width: 1460px) {
  .Main-Container {
    width: 90%;
  }
  .Service-main {
    width: 90%;
  }
  .Main-Agency {
    width: 90%;
  }
}
@media all and (max-width: 1150px) {
  .Main-Container {
    width: 95%;
  }
  .IconsImg1,
  .IconsImg2,
  .IconsImg3,
  .IconsImg4 {
    height: 50px;
    width: 50px;
  }
  .Card-text {
    font-size: 15px;
    line-height: 17px;
    margin: 20px 0px 0px 0px;
  }
  .Cardsetups {
    margin: 20px 11px;
  }
  .Agency-text {
    font-size: 16px;
  }
  .flag-animation {
    height: 20px;
    width: 20px;
  }
  .Main-textas {
    font-size: 40px;
    line-height: 50px;
    margin: 20px 0px 0px 0px;
    padding: 0px 22px 0px 0px;
  }
  .Get-Btn {
    font-size: 15px;
  }
  .Work-men {
    height: 390px;
    width: 90%;
  }
  .Main-Agency {
    margin: 0px auto 30px auto;
  }
  .Service-Subbloder {
    font-size: 40px;
    line-height: 40px;
    margin-top: 10px;
  }
  .Service-text {
    font-size: 20px;
    line-height: 33px;
  }
  .mainblock-service {
    margin-top: 20px;
  }
  .text-main-ser {
    font-size: 18px;
  }
  .subtext-main-ser {
    font-size: 14px;
    line-height: 18px;
    height: 65px;
  }
  .Discover-More {
    font-size: 14px;
    margin-top: 10px;
    line-height: 10px;
  }
}
@media all and (max-width: 880px) {
  .Main-Container {
    width: 95%;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .Card {
    width: 45%;
    height: auto;
    margin-bottom: 20px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.092) !important;
  }
  .Main-Agency {
    width: 96%;
  }
  .Agency-text {
    font-size: 13px;
  }
  .Main-textas {
    font-size: 30px;
    line-height: 35px;
  }
  .Get-Btn {
    margin-top: 20px;
  }
  .Work-men {
    height: 300px;
    width: 90%;
  }
  .BannerVid {
    margin-top: 0rem;
  }
  .videoTag {
    height: 450px;
  }
  .Service-main {
    width: 100%;
  }
  .block-container {
    width: 50%;
  }
  .mainblock-service {
    width: 90%;
    margin: 0 auto;
  }
}
@media all and (max-width: 550px) {
  .block-container {
    width: 100%;
  }
  .subtext-main-ser {
    height: 50px;
  }
  .Discover-More {
    margin-top: 15px;
  }
  .Service-Subbloder {
    font-size: 28px;
    line-height: 32px;
  }
}
@media all and (max-width: 450px) {
  .Main-Container {
    width: 100%;
  }
  .Card {
    width: 90%;
  }
  .Main-Agency {
    width: 90%;
    display: block;
  }
  .Agency-img {
    width: 100%;
  }
  .Agency-content {
    width: 100%;
    display: block;
  }
  .Work-men {
    height: 250px;
    width: 90%;
  }
}
