.Quote-Main {
  margin-top: 80px;
  width: 100%;
  background-color: var(--Blue);
}
.Block-Quote {
  width: 80%;
  max-width: 1200px;
  display: flex;
  margin: 0 auto;
  justify-content: space-around;
}
.Quote-Content {
  display: block;
}
.Setup-text {
  font-family: var(--Font-Family);
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: left;
  margin: 10px 0px 0px 0px;
}
.Setup-SubContant {
  font-family: var(--Font-Family);
  color: #c9c8c8;
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  line-height: 20px;
  margin: 10px 0px 20px 0px;
}
.Quote-btn {
  display: flex;
  align-items: center;
}
.Quan-De-b {
  color: #fff;
  background-color: #0f67f6;
  font-family: var(--Font-Family);
  font-size: 20px;
  padding: 10px 20px;
  border: 1px solid var(--Blue);
}
/* Btn Hover */
.centerer1 *,
.centerer1 *:before,
.centerer1 *:after {
  box-sizing: border-box;
}

.centerer1 span {
  transition: none;
}

.centerer1 a {
  text-decoration: none;
  color: black;
  transition: 0.5s ease-in-out;
}

.centerer1 [class^="btn-"] {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  height: 45px;
  /* max-width: 130px; */
  margin: 1rem auto;
  /* text-transform: uppercase; */
  border: none;
  /* border-radius: 10px; */
}

.centerer1 .btn-61 {
  color: #ffffff !important;
  text-align: center;
  font-family: "Josefin Sans", sans-serif;
  font-weight: 600;
  font-size: 16px;
  padding: 20px;
  align-items: center;
  display: flex;
  background-color: #0f67f6;
  z-index: 99;
  position: relative;
}

.centerer1 .btn-61 span {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-radius: 50%;
  background-color: #fff;
  transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.centerer1 .btn-61:hover {
  color: #0f67f6 !important;
}

.centerer1 .btn-61:hover span {
  width: 225%;
  height: 562.5px;
}

.centerer1 .btn-61:active {
  background-color: #956a87;
}

/* Btn Hover */
@media all and (max-width: 1050px) {
  .Block-Quote {
    width: 100%;
  }
  .Setup-text {
    font-size: 22px;
  }
  .Setup-SubContant {
    font-size: 16px;
    margin: 0px 0px 20px 0px;
  }
  .Quan-De-b {
    font-size: 16px;
  }
  .Quote-Main {
    margin-top: 40px;
  }
}
@media all and (max-width: 600px) {
  .Block-Quote {
    width: 95%;
  }
  .Setup-text {
    font-size: 18px;
  }
  .Setup-SubContant {
    font-size: 14px;
    margin: 0px 0px 20px 0px;
  }
}
@media all and (max-width: 500px) {
  .Setup-text {
    font-size: 14px;
  }
  .Quan-De-b {
    font-size: 10px;
    padding: 7px;
  }
  .Setup-SubContant {
    font-size: 12px;
    margin: 0px 0px 20px 0px;
  }
}
