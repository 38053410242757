.Main-Text-Portfolio {
  font-family: var(--Font-Family);
  font-size: 42px;
  font-weight: 700;
  text-align: center;
  color: var(--Blue);
  margin: 15px 0px;
}
.Portfolio-block {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
}

.Port-Card {
  width: calc(32% - 10px);
  margin-bottom: 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.Port-Img {
  width: 100%;
  height: auto;
  position: relative;
}
.Port-Bshow {
  border-radius: 4px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  object-fit: fill;
}
.P-Name {
  position: absolute;
  bottom: 30px;
  left: 20px;
  font-family: var(--Font-Family);
  color: #fff;
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
}
.P-Work {
  position: absolute;
  bottom: 0px;
  left: 20px;
  font-family: var(--Font-Family);
  font-size: 24px;
  font-weight: 700;
  color: #fff;
  line-height: 20px;
}
@media all and (max-width: 1300px) {
  .Portfolio-block {
    width: 95%;
  }
}
@media all and (max-width: 1160px) {
  .P-Work {
    font-size: 19px;
  }
  .P-Name {
    bottom: 20px;
    font-size: 15px;
  }
  .Main-Text-Portfolio {
    font-size: 33px;
  }
}
@media all and (max-width: 850px) {
  .P-Name {
    bottom: 10px;
    font-size: 15px;
    left: 10px;
  }
  .P-Work {
    font-size: 17px;
    left: 10px;
    bottom: -11px;
  }
}
@media all and (max-width: 650px) {
  .Main-Text-Portfolio {
    font-size: 22px;
  }
  .Port-Card {
    width: calc(49% - 10px);
    margin-bottom: 20px;
    box-sizing: border-box;
  }
  .Portfolio-block {
    justify-content: space-around;
  }
}
@media all and (max-width: 500px) {
  .P-Name {
    font-size: 11px;
    bottom: 5px;
  }
  .P-Work {
    font-size: 13px;
  }
}
