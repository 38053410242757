.Portfolio-Main {
  width: 100%;
  height: auto;
  background-image: url(./IMG/Back-banner.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.P-Boundry {
  width: 100%;
  margin: 0 auto;
}
.text-content {
  width: 80%;
  margin: 0 auto;
  max-width: 1200px;
}
.Classical {
  font-family: var(--Font-Family);
  font-size: 20px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0;
  color: #fff;
  padding-top: 50px;
}
.Head-type {
  font-family: var(--Font-Family);
  font-size: 50px;
  font-weight: 600;
  line-height: 55px;
  color: #fff;
  margin-top: 10px;
}
/* Slider Start */
.Slider-Main {
  height: auto !important;
  width: 100%;
}

/* Slider End */
