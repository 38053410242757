.Main-Contact {
  max-width: 1200px;
  width: 80%;
  margin: 20px auto 30px auto;
}
.C-latter {
  font-family: var(--Font-Family);
  font-size: 20px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
}
.Contact-text {
  font-family: var(--Font-Family);
  color: var(--Blue);
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0em;
  text-align: left;
}
.Form-info {
  width: 100%;
  display: flex;
}
.Contact-Form {
  width: 50%;
}
.Contect-Info {
  width: 50%;
}
.subdivision {
  display: flex;
}
.Inputs-item {
  width: 50%;
}
.Err {
  color: red;
  font-size: 10px;
  font-family: var(--Font-Family);
  font-weight: 500;
  margin-bottom: 0;
}
.List-Items {
  font-family: var(--Font-Family);
  color: #000;
  font-size: 16px;
  margin: 0;
  line-height: 1;
}
.input-item {
  font-family: var(--Font-Family);
  border: none;
  height: 20px;
  width: 90%;
  border-bottom: 2px solid gray;
}
.input-item1 {
  font-family: var(--Font-Family);
  border: none;
  height: 20px;
  width: 95%;
  border-bottom: 2px solid gray;
}
.input-item:focus {
  border-bottom: 2px solid gray;
}
.input-item1:focus {
  border-bottom: 2px solid gray;
}

.full-input {
  width: 100%;
  margin-top: 20px;
}
.Submit-Btn {
  color: #fff;
  background-color: var(--Yellow);
  font-family: var(--Font-Family);
  font-size: 16px;
  font-weight: 500;
  border: 1px solid var(--Yellow);
  padding: 5px 20px;
  border-radius: 30px;
  margin-top: 30px;
  transition: 0.3s ease-in-out;
}
.Submit-Btn:hover {
  color: #fff;
  background-color: var(--Yellow);
  font-family: var(--Font-Family);
  font-size: 16px;
  font-weight: 500;
  border: 1px solid var(--Yellow);
  padding: 5px 20px;
  border-radius: 30px;
  margin-top: 30px;
  transition: 0.3s ease-in-out;
}
.Submit-Btn:hover {
  color: #fff;
  background-color: var(--Blue);
  border-color: var(--Blue);
}
.Icon-text {
  width: 100%;
  height: 100px;
  margin: 20px;
}

.Info-flex {
  width: 100%;
  display: flex;
  height: 100px;
  /* overflow: hidden; */
}
.box-info {
  width: 50%;
  display: flex;
}
.Info-Icon {
  height: 45px;
  width: 45px;
  margin: 0 !important;
}
.Contact-tab {
  display: block;
  width: 78%;
  margin: auto;
}
.Iconicals-width {
  width: 22%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Contact-Dialer {
  font-family: var(--Font-Family);
  font-size: 20px;
  font-weight: 600;
  line-height: 18px;
  margin: 10px 0px 0px 0px;
  color: var(--Blue);
}
.Dial {
  font-family: var(--Font-Family);
  font-size: 17px;
  line-height: 10px !important;
  font-weight: 700;
  color: #000 !important;
}

@media all and (max-width: 1150px) {
  .Main-Contact {
    width: 90%;
  }
}
@media all and (max-width: 950px) {
  .Form-info {
    width: 100%;
    display: block;
  }
  .Contact-Form {
    width: 100%;
  }
  .Contect-Info {
    width: 100%;
  }
  .Contact-text {
    font-size: 40px;
    line-height: 20px;
    margin-bottom: 30px;
  }
}
@media all and (max-width: 650px) {
  .C-latter {
    font-size: 16px;
    line-height: 10px;
  }
  .Contact-text {
    font-size: 35px;
    line-height: 20px;
  }
  .List-Items {
    font-size: 14px;
  }
  .Submit-Btn {
    font-size: 14px;
  }
  .Contact-Dialer {
    font-size: 18px;
  }
  .Dial {
    font-size: 14px;
  }
  .Info-Icon {
    height: 40px;
    width: 40px;
  }
}
@media all and (max-width: 500px) {
  .Contact-text {
    font-size: 25px;
    line-height: 20px;
  }
  .C-latter {
    font-size: 15px;
    line-height: 10px;
  }

  .Contact-Dialer {
    font-size: 15px;
  }
  .Dial {
    font-size: 12px;
  }
}
@media all and (max-width: 400px) {
  .Contact-text {
    font-size: 22px;
    line-height: 20px;
  }
  .Info-flex {
    display: block;
    height: auto;
  }
  .box-info {
    width: 80%;
  }
  .Info-flex {
    display: block;
    margin-top: 30px;
  }
  .box-info {
    width: 80%;
    margin: 10px auto;
    display: block;
  }
  .Iconicals-width {
    width: 100%;
  }
  .Contact-tab {
    text-align: center;
  }
  .Info-Icon {
    height: 50px;
    width: 50px;
  }
  .Contact-Dialer {
    font-size: 20px;
  }
  .Dial {
    font-size: 16px;
  }
}
