.cursor {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #055393;
  transition: all 200ms ease-out;
  position: fixed;
  pointer-events: none;
  left: 0;
  top: 0;
  transform: translate(calc(-50% + 15px), -50%);
  z-index: 99999999999;
}
.cursor2 {
  z-index: 99999999999;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background-color: #eb6f20;
  opacity: 0.6;
  position: fixed;
  transform: translate(-50%, -50%);
  pointer-events: none;
  /* transition: width 0.9s, height 0.9s, opacity 0.9s; */
}
.cursor.hover {
  background-color: #055393;
  opacity: 0.5;
}
.cursorinnerhover {
  width: 50px;
  height: 50px;
  opacity: 0.5;
}
@media all and (max-width: 900px) {
  .cursor {
    display: none;
  }
  .cursor2 {
    display: none;
  }
}
